var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Create New Communication',
    key: 'createNewCommunication'
  },"topActionButtonSecondary":_vm.topButtonSecondaryItems},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":_vm.loadingText,"data":_vm.communications,"paginationLinks":_vm.links,"totalRecords":_vm.getTotalRecords,"tableActions":_vm.tableActions,"showAdvancedFilter":false,"tableColumns":_vm.tableColumns,"maxQuerySize":_vm.getMaxQuerySize,"filters":_vm.filters,"tableRowActions":_vm.tableRowActions},on:{"tableRowClicked":_vm.viewCallback,"fetch-query":_vm.fetchPageData,"table-row-action":_vm.handleResponseAction}}),_c('ModalBase',{attrs:{"name":"showReadersModal","size":"large","clickToClose":false,"showClose":true,"title":"Communication Readers","loading":_vm.makingApiRequest,"position":"center"}},[_c('CommunicationReaders',{attrs:{"communicationId":_vm.readersModalCommunicationId}})],1),_c('ModalBase',{attrs:{"name":"showRecipientsModal","size":"large","clickToClose":false,"showClose":true,"title":"Communication Recipients","loading":_vm.makingApiRequest,"position":"center"}},[_c('CommunicationRecipients',{attrs:{"communicationId":_vm.recipientsModalCommunicationId}})],1),_c('ModalMessage',{attrs:{"name":"communicationsDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":"This will delete the communication. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.deleteOneCommunication()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('communicationsDeleteModal')
      }
    ]}}),_c('ModalMessage',{attrs:{"name":"communicationsExpireModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":"This will expire communication and all related notifications. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.expireOneCommunication()
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }