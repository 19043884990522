
import Vue from "vue";
import {
  communicationMapActions,
  communicationMapGetters,
  communicationMapMutations,
  communicationMapState
} from "@/store/modules/communication";
import { tableColumnsListCommunications } from "./ListCommunicationsTableColumns";
import { ITableWrapperColumns } from "@/types";
import * as types from "@/store/mutation-types";
import { getCommunicationDefaults } from "@/helpers/defaultObjectGenerators";
import * as selectOptions from "@/helpers/selectOptions";
import * as dateFns from "date-fns";

interface CommunicationsData {
  loading: boolean;
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  readersModalCommunicationId: string;
  recipientsModalCommunicationId: string;
  errorMessage: string;
  successMessage: string;
  filters: any[];
  tableRowActions: any[];
  communicationId: string;
  loadingText: string;
}

export default Vue.extend({
  name: "listCommunication",
  components: {
    CommunicationReaders: () => import("./CommunicationReaders.vue"),
    CommunicationRecipients: () => import("./CommunicationRecipients.vue")
  },
  data(): CommunicationsData {
    return {
      loading: false,
      tableActions: [],
      tableColumns: tableColumnsListCommunications,
      readersModalCommunicationId: "",
      recipientsModalCommunicationId: "",
      errorMessage: "",
      successMessage: "",
      communicationId: "",
      filters: [
        {
          field: "priority",
          title: "Priority",
          options: selectOptions.communicationPriority
        },
        {
          field: "status",
          title: "Status",
          options: selectOptions.communicationStatus
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ],
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Expire",
          value: "expire",
          faIcon: "bell-slash",
          show: (row: any): boolean => {
            return !!(
              dateFns.isAfter(new Date(), new Date(row.expirationDate)) &&
              row.status !== "draft"
            );
          }
        },
        {
          label: "View Readers",
          value: "readers",
          faIcon: "user",
          show: (row: any) =>
            !!(row.status === "completed" && row.requireAcknowledgements)
        },
        {
          label: "Recipients",
          value: "recipients",
          faIcon: "user",
          show: (row: any) => !!(row.status === "completed")
        },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          show: (row: any) => row.status === "draft",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...communicationMapActions([
      "getCommunications",
      "deleteCommunication",
      "createCommunication",
      "expireCommunication"
    ]),
    ...communicationMapMutations([types.SET_STATE]),
    async fetchPageData(queryObject: any): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Loading communications. Please wait...";
        this.$emit("scrollTop");
        await this.getCommunications({ query: queryObject });
      } catch (error) {
        this.errorMessage = "Error fetching communications. Try again later";
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    canExpireCommunication(row: any): boolean {
      return !!(
        dateFns.isAfter(new Date(), new Date(row.expirationDate)) &&
        row.status !== "draft"
      );
    },
    viewCallback(dataReceived: any): void {
      if (["completed", "dispatched"].includes(dataReceived.status)) {
        this.$router
          .push(`/admin/communications/${dataReceived._id}/completed`)
          .catch(() => {});
      } else {
        this.$router
          .push(`/admin/communications/${dataReceived._id}`)
          .catch(() => {});
      }
    },
    async onToolbarItemClick(event: string) {
      switch (event) {
        case "createNewCommunication":
          await this.createCallback();
          break;
        case "communication-activities":
          this.$router
            .push({
              name: "CommunicationActivities",
              path: `/admin/communications/bulk/activities`
            })
            .catch(() => {});
          break;
        default:
          return null;
      }
    },
    async createCallback(): Promise<void> {
      try {
        const newCommunication = await this.createCommunication(
          getCommunicationDefaults({})
        );
        this.$router.push(`/admin/communications/${newCommunication._id}`);
      } catch (error) {
        this.errorMessage = "Error creating new communication";
        this.$bugSnagClient.notify(error);
      }
    },
    async confirmRemove(): Promise<void> {
      this.$modal.show("communicationsDeleteModal");
    },
    async deleteOneCommunication() {
      await this.deleteCommunication(this.communicationId);
      this.$modal.hide("communicationsDeleteModal");
      this.$appNotifySuccess("Communication deleted");
    },
    async confirmExpireCommunication() {
      this.$modal.show("communicationsExpireModal");
    },
    async expireOneCommunication() {
      await this.expireCommunication(this.communicationId);
      this.$modal.hide("communicationsExpireModal");
      this.$appNotifySuccess("Communication expired");
    },
    async handleResponseAction(payload: {
      name: string;
      data: Record<string, any>;
    }) {
      switch (payload.name) {
        case "expire":
          try {
            this.loading = true;
            this.loadingText = "Expiring communication. Please wait...";
            this.communicationId = payload.data._id;
            await this.confirmExpireCommunication();
          } catch (error) {
            if (error !== "cancel") {
              this.errorMessage = error;
              this.$bugSnagClient.notify(error);
            }
          } finally {
            this.loading = false;
            this.loadingText = "";
          }
          break;
        case "delete":
          try {
            this.loading = true;
            this.loadingText = "Deleting communication. Please wait...";
            const { _id } = payload.data;
            this.communicationId = _id;
            await this.confirmRemove();
          } catch (error) {
            if (error != "cancel") {
              this.errorMessage = error;
              this.$bugSnagClient.notify(error);
            }
          } finally {
            this.loading = false;
            this.loadingText = "";
          }
          break;
        case "edit":
          this.viewCallback(payload.data);
          break;
        case "readers":
          this.readersModalCommunicationId = payload.data._id;
          this.$modal.show("showReadersModal");
          break;
        case "recipients":
          this.recipientsModalCommunicationId = payload.data._id;
          this.$modal.show("showRecipientsModal");
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    }
  },
  computed: {
    ...communicationMapGetters(["getTotalRecords", "getMaxQuerySize"]),
    ...communicationMapState(["links", "communications", "makingApiRequest"]),
    topButtonSecondaryItems() {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const subOptions = [
        {
          title: "All Activities",
          command: "communication-activities"
        }
      ];

      return {
        ...primary,
        subItems: subOptions
      };
    }
  }
});
